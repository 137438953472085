<template>
    <div class="about-us-page">
        <why-lawback></why-lawback>
       <div class="lv-basic-card lv-mt-80">
           <easy-title class="lv-mb-52">
               Contact Us
           </easy-title>
           <div class="contact-us-content lv-mb-52">
               <div><label>TEL:</label> 400 1260 666  Add</div>
               <div><label>Email:</label> official@lawback.com</div>
               <div style="display: flex">
                   <div><label>Add:</label></div>
                   <div>
                       Service Centre in Northern China: R401, 4th Floor, International Chamber of Commerce Bld. Huapi Chang Hutong No.2, Xicheng Dist., Beijing<br>
                       Service Centre in Central China: R2312, Bld F2, Lugu Enterprises Plaza,Yuelu Dist. Changsha City., Hunan<br>
                       Service Centre in Eastern China: 1st Floor, Bld.2, Huashen Temple No.10, Yuhua Tai Dist., Nanjing City, Jiangsu
                   </div>
               </div>
           </div>
           <hr>
           <div class="lv-basic-card lv-mt-60">
               <easy-title class="lv-mb-52">Join Us</easy-title>
               <el-form label-width="130px" ref="form" :model="formData" :rules="rules">
                   <el-row :gutter="70">
                       <el-col :span="12">
                           <easy-form-item class="lv-mb-24" label="NAME" prop="uname" >
                               <el-input maxlength="20" v-model="formData.uname" placeholder="You name" ></el-input>
                           </easy-form-item>
                       </el-col>
                       <el-col :span="12">
                           <easy-form-item class="lv-mb-24"  label="COUNTRY" prop="countryCode">
                               <el-select filterable placeholder="You country"  v-model="formData.countryCode" >
                                   <el-option v-for="item in countries" :key="item.code" :value="item.code" :label="item.nameEn" ></el-option>
                               </el-select>
                           </easy-form-item>

                       </el-col>
                       <el-col :span="12">
                           <easy-form-item class="lv-mb-24"  label="TEL" prop="phone">
                                <el-input maxlength="20"  placeholder="You telephone number" v-model.number="formData.phone" ></el-input>
                           </easy-form-item>
                       </el-col>
                       <el-col :span="12">
                           <easy-form-item class="lv-mb-24" label="EMAIL" prop="email">
                               <el-input maxlength="20" placeholder="You email" v-model="formData.email" ></el-input>
                           </easy-form-item>

                       </el-col>
                   </el-row>
                   <div class="lv-text-center lv-mt-24">
                       <el-button @click="submit" class="lv-primary-circle-button" type="primay" >Submit</el-button>
                   </div>
               </el-form>
           </div>
       </div>
    </div>
</template>

<script>
    import WhyLawback from "../../components/WhyLawback/WhyLawback";
    import EasyTitle from "../../components/EasyTitle/EasyTitle";

    var formData = {
        "type": "8",
        "countryCode": "",
        "uname": "",
        "email":"",
        "phone": "",
        "src":"1"
    }
    export default {
        name: "aboutUs",
        components:{
            EasyTitle,
            WhyLawback
        },
        data(){
          return {
              countries:[],
              formData:{...formData},
              rules:{
                  "countryCode":{required:true,message:"You country",trigger:'blur'},
                  "email": {required:true,message:"You email",trigger:['change','blur']},
                  "uname": {required:true,message:"You name",trigger:['change','blur']},
                  "phone": {required:true,message:"You telephone number",trigger:['change','blur']},
              }
          }
        },
        created() {
            this.allCountries();
        },
        methods:{
            async allCountries(){
                let data = await this.$http.get("/cms/country/list");
                this.countries = data;
            },
            submit(){
                this.$refs.form.validate(async(valid) => {
                    if (valid) {
                        let params = {...this.formData};
                        let country = this.countries.find(item=>item.code == this.formData.countryCode);
                        params.countryName = country.nameEn;
                        console.log(params)
                        let data = await this.$http.put("/cms/customerResources/",params);
                        this.$message({
                            type:"success",
                            message:"success",
                            duration:3000
                        })

                        this.formData = {...formData};
                        this.$refs.form.resetFields();
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.about-us-page{
    padding-top: 80px;
    .contact-us-content{
        font-size: 20px;
        font-family: Arial;
        color: $content;
        line-height: 36px;
        label{
            width: 90px;
            font-weight: bold;
            color:$title;
            display: inline-block;
        }
    }

}
</style>